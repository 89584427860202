import React from "react";
import PropTypes from "prop-types";

const HeroTitle = ({ tag, title, ...rest }) => {
  const TagName = `${tag || "h2"}`;

  return (
    <div class="hero" {...rest}>
      <TagName className="hero__title">{title}</TagName>
    </div>
  );
};

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeroTitle;
